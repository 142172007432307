import React, { useState } from "react"
import styled from "styled-components"
import ImageUploader from '../components/ImageUploaderOld'
import Button from "../components/UI/Button"
import { colors } from "../theme"

const Wrapper = styled.div`

`

const Image = styled.img``

const Url = styled.div`
  background-color: #fff;
  height: 40px;
  padding: 10px 30px;
  color: #000;
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;
`

const Input = styled.input`
  margin-bottom: 20px;
`

const UploadPage = ({ email }) => {
  const [basePath, setBasePath] = useState('upload/images/')
  const [url, setUrl] = useState()

  if (typeof window === "undefined") return null

  return <Wrapper>
    { !url && <>
      <div>Upload path:</div>
      <div>
        <span>cdn.nightzookeeper.com/</span><Input value={basePath} onChange={(e) => setBasePath(e.target.value)} /><span>FILENAME/ID</span>
      </div>
      <div>File:</div>
      <ImageUploader basePath={basePath} onComplete={(url) => setUrl(url)} />
    </>}
    {
      url && <>
        <Url>{url}</Url>
        <Image src={url} />
        <div>
          <Button bgColor={colors.indigo} onClick={() => setUrl()}>Upload something else</Button>
        </div>
      </>
    }
  </Wrapper>
}

export default UploadPage
